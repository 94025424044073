import React, { useEffect } from 'react';
import NavBar from './NavBar';
import ToggleSwitch from './ToggleSwitch';
import ConcertCalendar from './ConcertCalendar';

const ShowLayout = () => {
  useEffect(() => {
    document.body.classList.add('show-mode');
    document.body.classList.remove('song-mode');
    return () => document.body.classList.remove('show-mode');
  }, []);

  return (
    <div className="show-layout">
      <NavBar />
      <h2 className="headline">Gotham Sonic</h2>
      <header className="App-header">
        <div className="logo-container">
          <ToggleSwitch />
        </div>
      </header>
      <ConcertCalendar />
    </div>
  );
};

export default ShowLayout;

