import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ToggleSwitch = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSongs = location.pathname === "/songs";

  const handleToggle = () => {
    navigate(isSongs ? "/shows" : "/songs");
  };

  return (
    <div
      className="toggle-wrapper"
      style={{
        position: "absolute", // Makes it overlap elements
        top: "310px", // Moves it higher
        left: "34%", // Centers horizontally
        transform: "translateX(-50%)", // Ensures perfect centering
        zIndex: 10, // Ensures it appears on top
      }}
    >
      <div
        className="toggle-container"
        onClick={handleToggle}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "70px", // Slightly longer
          height: "35px",
          backgroundColor: isSongs ? "#f0f0f0" : "#FFD700", // Default yellow, switches to gray
          borderRadius: "20px",
          padding: "4px",
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
      >
        <div
          style={{
            width: "30px", // Adjusted for proportion
            height: "30px",
            backgroundColor: "#fff",
            borderRadius: "50%",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.2)",
            transform: isSongs ? "translateX(35px)" : "translateX(0px)",
            transition: "transform 0.3s",
          }}
        ></div>
      </div>
    </div>
  );
};

export default ToggleSwitch;
