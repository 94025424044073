import React, { useEffect } from 'react';
import NavBar from './NavBar';
import ToggleSwitch from './ToggleSwitch';
import SongMode from './SongMode';

const SongLayout = () => {
  useEffect(() => {
    document.body.classList.add('song-mode');
    document.body.classList.remove('show-mode');
    return () => document.body.classList.remove('song-mode');
  }, []);

  return (
    <div className="song-layout">
      <NavBar />
        <ToggleSwitch />
      <SongMode />
    </div>
  );
};

export default SongLayout;


