import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import ShowLayout from './ShowLayout';
import SongLayout from './SongLayout';
import './App.css';

const isMobileDevice = () => {
  return /android|iphone|ipad|ipod|blackberry|mini|windows\sce|palm/i.test(navigator.userAgent.toLowerCase());
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to={isMobileDevice() ? "/shows" : "/songs"} />} />
        <Route path="/shows" element={<ShowLayout />} />
        <Route path="/songs" element={<SongLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
