import React, { useEffect, useState } from 'react';

const ConcertCalendar = () => {
  const [concerts, setConcerts] = useState([]);
  const [selectedBorough, setSelectedBorough] = useState("All");
  const [currentWeek, setCurrentWeek] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isNextWeek, setIsNextWeek] = useState(false);
  const [weekOffset, setWeekOffset] = useState(0); // Starts at 0 (current week), can go up to 2 (two weeks ahead)
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const BASE_URL = 'https://gothamsonic.com';

  const formatDate = (date) => {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`;
  };


  // New function for displaying dates in the UI
  const formatDisplayDate = (date) => {
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  const getDisplayText = (date, bgColor) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (date.toDateString() === today.toDateString()) {
        return (
            <span style={{ color: "#ADD8E6" }}>
                Shows in NYC Today ({formatDisplayDate(date)})
            </span>
        );
    } else if (date.toDateString() === tomorrow.toDateString()) {
        return (
            <span style={{ color: "#FC7C12" }}>
                And Tomorrow ({formatDisplayDate(date)})...
            </span>
        );
    } else {
        const textColor = bgColor === "#F0D878" ? "#FF00FF" : 
                          bgColor === "#A1CFCF" ? "#FC7C12" : 
                          bgColor === "#8FBC8F" ? "#8931e8" :
                          bgColor === "#E6A8B8" ? "#2F4F4F" :
                          bgColor === "#333333" ? "#ADD8E6" :
                          "white";

        return (
            <span style={{ color: textColor }}>
                Shows on {date.toLocaleDateString("en-US", { weekday: "short" })} ({formatDisplayDate(date)})
            </span>
        );
    }
};

  useEffect(() => {
    const formattedDate = formatDate(selectedDate);
    fetch(`${BASE_URL}/api/shows/?date=${formattedDate}`)
      .then((response) => response.json())
      .then((data) => setConcerts(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [selectedDate]);

// Handle button animations on mount
  useEffect(() => {
    const buttons = document.querySelectorAll('.button');
    buttons.forEach((button, index) => {
      //const directions = ['animate-fly-in-left', 'animate-fly-in-right', 'animate-fly-in-top', 'animate-fly-in-bottom'];
      const directions = [
        'animate-fly-in-left-diagonal', 
        'animate-fly-in-right-diagonal', 
        'animate-fly-in-bottom-left', 
        'animate-fly-in-bottom-right'
      ];
     

      const randomDirection = directions[index % directions.length];
      setTimeout(() => {
        button.classList.add(randomDirection);
      }, index * 100);
    });

    setTimeout(() => {
      setIsAnimationComplete(true);
    }, 1000);
  }, []);



  const boroughs = [
    "All",
    "Manhattan",
    "Brooklyn",
    "Queens",
    "Bronx",
    "Staten Island",
  ];

  

  const handleBoroughChange = (e) => {
    setSelectedBorough(e.target.value);
  };

  const handleButtonClick = (callback) => {
    if (isAnimationComplete) {
       callback();
    }
  };



const colorMap = [
    "rgba(51, 51, 51, 1)", // Grey for Today #333333 equivalent
    "#A1CFCF", // // Muted Turquoise
    "#8FBC8F",  // Muted Green     
    "#F0D878",  // Softer Yellow (New) 
    "#E6A8B8",  //  Muted Pink 
    "#A586C0",  // Muted Purple                
    "#E57373",  // Muted Red          
];

  const [selectedColor, setSelectedColor] = useState(colorMap[0]); // Default to grey

  const getTextColor = (bgColor) => {
    return bgColor === "rgba(51, 51, 51, 1)" ? "white" : "black";
  };

  const getVenueColor = (bgColor) => {
    return bgColor === "rgba(51, 51, 51, 1)" ? "#ADD8E6" : "#0202f7"; 
  };


  

  const handleDateChange = (date,index) => {
    setSelectedDate(date);
    setSelectedColor(colorMap[index % colorMap.length]);
  };

  const handleWeekChange = (direction) => {
    setWeekOffset((prevOffset) => {
      let newOffset = prevOffset;
      if (direction === "next" && prevOffset < 2) {
        newOffset = prevOffset + 1;
      } else if (direction === "previous" && prevOffset > 0) {
        newOffset = prevOffset - 1;
      }
      // Assuming the start of the week is what we want to display
      const newWeekDays = getWeekDays(newOffset);
      setSelectedDate(newWeekDays[0]);
      setSelectedColor(colorMap[0]); // Reset to grey
      return newOffset;
    });
  };
  const getWeekDays = () => {
    const start = new Date(currentWeek);
    start.setDate(start.getDate() + weekOffset * 7); // Move start date by 7 days per week offset
    return Array.from({ length: 7 }).map((_, index) => {
      const date = new Date(start);
      date.setDate(start.getDate() + index);
      return date;
    });
  };

  const getFilteredConcerts = () => {
    if (selectedBorough === "All") {
      return concerts.reduce((acc, concert) => {
        const borough = concert.venue_name.borough.name;
        if (!acc[borough]) {
          acc[borough] = [];
        }
        acc[borough].push(concert);
        return acc;
      }, {});
    } else {
      return {
        [selectedBorough]: concerts.filter(
          (concert) => concert.venue_name.borough.name === selectedBorough
        ),
      };
    }
  };

  const filteredConcerts = getFilteredConcerts();

return (
    <div className="concert-calendar" style={{ backgroundColor: selectedColor, color: getTextColor(selectedColor) }}>
    
     <h3>{getDisplayText(selectedDate, selectedColor)}</h3>
      {boroughs.map((borough) => (
        <label key={borough} style={{ fontSize: '1.3rem', marginRight: '5px' }}>
          <input
            type="radio"
            name="borough"
            value={borough}
            checked={selectedBorough === borough}
            onChange={handleBoroughChange}
	    style={{ marginRight: '5px' }}   
          />
          {borough}
        </label>
      ))}
      <div className="date-selector">
        {getWeekDays().map((day, index) => (
          <button
            key={day.toISOString()}
            onClick={() => handleDateChange(day, index)}
            className="button"
            style={{
              backgroundColor: selectedDate.toDateString() === day.toDateString() ? colorMap[index % colorMap.length] : "#f0f0f0"
            }}
          >
            <div className="weekday">
              {day.toLocaleDateString("en-US", { weekday: "short" })}
            </div>
            <div className="date">{`${day.getMonth() + 1}/${day.getDate()}`}</div>
          </button>
        ))}
        {weekOffset === 0 && <button className="button"> </button>}
        {weekOffset > 0 && (
          <button
            onClick={() => handleWeekChange("previous")}
            className="button"
          >
            <div style={{ fontWeight: 'bold' }}>Last Week</div>
          </button>
        )}
        {weekOffset < 2 && (
          <button onClick={() => handleWeekChange("next")} className="button">
            <div style={{ fontWeight: 'bold' }}>Next Week</div>
          </button>
        )}
        {weekOffset === 2 && <button className="button"></button>}
      </div>
      <div>
        {Object.entries(filteredConcerts).map(([boroughName, concerts]) => (
          <div key={boroughName}>
		<h3 style={{ color: selectedColor === "#F0D878" ? "#FF00FF" :
                     selectedColor === "#A1CFCF" ? "#F58426" :
                     selectedColor === "#8FBC8F" ? "#8931e8" :
                     selectedColor === "#E6A8B8" ? "#2F4F4F" :
                     "white" }}>
                 {boroughName}
                </h3>

            <ul className="show-list">
              {concerts.map((concert) => (
                <li key={concert.id}>
		   <strong style={{ color: getVenueColor(selectedColor) }}>
                    {concert.venue_name.website_url ? (
                      <a href={concert.venue_name.website_url} target="_blank" rel="noopener noreferrer">
                        {concert.venue_name.name}
                      </a>
                    ) : (
                      concert.venue_name.name
                    )}
                  </strong>
                  :{" "}
                  {concert.artists.map((artist, index) => (
                    <span key={index}>
                      {index > 0 && ", "}
                      {artist.band_website_url ? (
                        <a href={artist.band_website_url} target="_blank" rel="noopener noreferrer">
                          {artist.name}
                        </a>
                      ) : (
                        artist.name
                      )}
                    </span>
                  ))}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ConcertCalendar;
